/* ----------------------index page, home set 1---------------------- */
.home1{
    height: auto;
    width: auto;
    padding: 20px;
    color: var(--whitetxt);
    background: linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0)),url(back_home1.png) center center/cover no-repeat;
}
.h1item{
    display: grid;
    padding: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}
.h1item1{
    grid-column-start: 1;
    grid-column-end: 3;
    font-size: 60px;
}
.h1item3 p{
    font-size: 15px;
}
.h1item3 button{
    /* margin-top: 10px; */
    border-radius: 30px;
    width: 140px;
    height: 35px;
    background: var(--greentxt);
    border: none;
    color: var(--whitetxt);
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.h1item3 button i{
    color: var(--whitetxt);
    padding-left: 5px;
    font-size: 20px;
}

@media screen and (max-width: 1200px){
    .home1{
        background: linear-gradient(rgba(0, 0, 0, 0.693),rgba(0, 0, 0, 0.624)),url(back_home1.png) center center/cover no-repeat;
    }
    .h1item1{
        font-size: 50px;
    }
    .h1item3 p{
        font-size: 15px;
    }
}

@media screen and (max-width: 600px) {
    .home1{
        background: linear-gradient(rgba(0, 0, 0, 0.693),rgba(0, 0, 0, 0.624)),url(back_home1.png) center center/cover no-repeat;
    }
    .h1item{
        grid-template-columns: repeat(1, 1fr);
    }
    .h1item1{
        font-size: 40px;
    }
    .h1item3 h5{
        font-size: 11px;
    }
    
}
/* home set 1 ends */

/* ----------------------index page, home set 2---------------------- */
.home2{
    height: 700px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 15px;
    padding: 20px;
}
.h2item2{
    font-size: 180px;
    font-weight: 700;
    color: var(--greentxt);
    justify-self: end;
    align-self: center;
}
.h2item3{
    align-self: center;
    color: var(--blacktxt);
    /* font-size: 18px; */
    font-weight: 600 ;
    /* margin-right: 250px; */
}
.h2item3 ul li{
    padding: 10px 0px;
}
.h2item3 button{
    border-radius: 30px;
    width: 200px;
    height: 40px;
    background: var(--greentxt);
    border: none;
    color: var(--whitetxt);
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.h2item3 button i{
    padding-left: 5px;
    font-size: 20px;
}

@media screen and (max-width:1200px) {
    .home2{
        grid-template-columns: repeat(2, 1fr);
        height: auto;
        padding: 60px 20px;
    }
    .h2item1, .h2item4{
        display: none;
    }
    .h2item3{
        margin-right: 20%;
    }
    
}

@media screen and (max-width: 700px) {
    .home2{
        height: 600;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 0px;
    }
    .h2item2, .h2item3{
        justify-self: center;
    }
    .h2item2{
        font-size: 150px;
    }
    .h2item3{
        padding: 0px 30px;
        margin-right: 0;
    }
}
/* home set 2 ends */

/* ----------------------index page, home set 3---------------------- */
.home3{
    height: 700px;
    width: 100%;
    background: var(--greentxt);
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    color: var(--whitetxt);
   
    /* grid-template-rows: repeat(2, 1fr); */
}

.h3item1{
    grid-column: 1/ span 3;
}
.h3item1 img{
    height: 700px;
    width: auto;
}

.h3item{
    grid-column: 4/ span 7;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    /* padding: 0px 20px; */
    padding: 20px 10%;
}
.h3item2{
    grid-column: 1/ span 2;
    justify-self: center;
    align-self: end;
    font-size: 50px;
    font-weight: 600;
}
.h3item3{
    padding: 5% 5% 0 0;
    font-size: 20px;
    font-weight: 600;
}

.h3item4{
    padding-top: 5%;
    font-size: 12px;
    font-weight: 600;
}

.h3item4 button{
    border-radius: 30px;
    width: 160px;
    height: 35px;
    margin-top: 25px;
    background: var(--whitetxt);
    border: none;
    color: var(--greentxt);
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
.h3item4 button i{
    font-size: 20px;
    padding-left: 5px;
}
@media screen and (max-width: 1100px){
    .h3item{
        padding: 20px 5%;
    }
    .h3item2{
        font-size: 40px;
        align-self: center;
    }
    .h3item4, .h3item3{
        padding-top: 0;

    }
}
@media screen and (max-width:900px) {
    .home3{
        height: 600px;
    }
    .h3item1 img{
        height: 600px;
    }
    .h3item{
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    .h3item2{
        font-size: 30px;
        align-self: center;
    }
    .h3item4, .h3item3{
        padding-top: 20px;

    }   
}
@media screen and (max-width: 700px) {
    .home3{
        grid-template-columns: repeat(1, 1fr);
    }
    .h3item1{
        display: none;
    }
    .h3item{
        background: linear-gradient(rgba(0, 0, 0, 0.650),rgba(0, 0, 0, 0.650)),url(h3img1.png) center center/cover no-repeat;
        height: auto;
        padding: 30px;
        background-blend-mode:darken;
        animation: change 10s infinite ease-in-out;
    }
    @keyframes change {
        25%
        {
            background: linear-gradient(rgba(0, 0, 0, 0.650),rgba(0, 0, 0, 0.650)),url(h3img2.png) center center/cover no-repeat;
        }
        50%
        {
            background: linear-gradient(rgba(0, 0, 0, 0.650),rgba(0, 0, 0, 0.650)),url(h3img3.png) center center/cover no-repeat;
        }
        75%
        {
            background: linear-gradient(rgba(0, 0, 0, 0.650),rgba(0, 0, 0, 0.650)),url(h3img4.png) center center/cover no-repeat;
        }
    }
}
/* ----------------------home set 3 end---------------------- */

/* ----------------------index page, home set 4---------------------- */
.home4{
    margin: 20px;
}
.h4item1{
    margin: 10% 0 30px 0;
    text-align: center;
    font-size: 50px;
    font-weight: 600;
}
.h4item{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-template-rows: repeat(2, 1fr); */
    gap: 1rem;
}
.h4item2, .h4item3, .h4item4, .h4item5{
    width: 100%;
    height: 100%;
}
.h4item11{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1rem;
    /* grid-template-rows: repeat(2, 1fr); */
}

.h4item5{
    grid-column: 1 / span 2;
    /* height: auto; */
}
@media screen and (max-width: 800px) {
    .h4item1{
        font-size: 40px;
    }   
}
@media screen and (max-width: 600px) {
    .home4{
        margin-top: 70px;
    }
    .h4item1{
        font-size: 30px;
    }   
    .h4item{
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        align-items: center;
    }
    .h4item11{
        justify-items: center;
    }
    .h4item,.h4item11{
        gap: 5px;
    }
    .h4item2, .h4item3, .h4item4, .h4item5{
        width: 100%;
        height: 90%;
    }
}


