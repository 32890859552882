
/* --------------------- Work Tab ---------------------*/
.wrkhm{
    margin-top: 10%;
}
.wrkhmtitle{
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    padding: 20px;
}
.wrkhmnav{
    display: flex;
    justify-content: center;
    margin: 60px 0px;
    padding: 20px 20px;
    border-top: 1px solid var(--blacktxt);
    border-bottom: 1px solid var(--blacktxt);
}
.wrkhmnav-link{
    margin: 0 30px;
    width: auto;
    padding: 10px;
    /* min-width: 100px; */
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: var(--greentxt);
    background: var(--whitetxt);
    /* background: var(--greentxt);
    color: var(--whitetxt); */
    border-radius: 25px;
}
.wrkhmnav-link:focus{
    background: var(--greentxt);
    color: var(--whitetxt);
    border-radius: 25px;
}
/* --------------------- Work Tab ends---------------------*/

/* --------------------- Films Tab---------------------*/
.wrkhmfilms{
    padding: 0px 30px;
    margin: 10px 0;
    display: grid;
    grid-auto-flow: column;
    column-gap: 1.5rem;
    justify-items: center;
    overflow-x: auto ;
    scroll-behavior: smooth; 
    -webkit-overflow-scrolling: touch;
    
    /* flex-wrap: wrap; */
}
.wrkhmfilms::-webkit-scrollbar{
    width: 5px;
    height: 10px;
    background: var(--whitetrans);
    /* margin-top: 20px; */
}
.wrkhmfilms::-webkit-scrollbar-thumb {
    background: var(--greentxt);
    /* border-radius: 5px; */
}

.film{
    width: 350px;
    /* position: relative; */
    /* height: 600px; */
    background: var(--blacktxt);
    padding: 25px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
    /* margin: 10px; */
    overflow: hidden;
}
.filmimg{
    width: 100%;
}
.imgtxt{
    position: relative;
}
.film:hover img{ 
    opacity: 0.2;
    filter: blur(2px);
    transition: all 0.5s ease;
}
.filmabt{
    position: absolute;
    left:0;
    /* top: 50%; */
    bottom: 0%;
    width: 100%;
    padding: 10px;
    text-align: center;
    color: var(--whitetxt);
    opacity: 0;
}
.filmabt p{
    font-weight: 600;
}
.film:hover .filmabt{
    transition: all 0.6s ease;
    opacity: 1;
}
.filmtxt{
    color: var(--whitetxt);
    margin-top: 15px;
}
.filmtxt h4{
    font-weight: 600;
}
.filmtxt hr{
    width: 20%;
}
.filmtxt h6{
    font-weight: 500;
    font-size: 15px;
    margin: 25px 0;
}
.filmtxt a{
    text-decoration: none;
}
.filmtxt p{
    font-size: 11px;
}

@media screen and (max-width: 800px) {
    .wrkhmtitle{
        font-size: 40px;
    }
    .film{
        width:325px;
    }
    
}
@media screen and (max-width: 600px ){
    .film{
        width:300px;
    }
}
@media screen and (max-width: 400px ) {
    .wrkhm{
        margin: 70px 10px 10px 10px;
    }
    .wrkhmfilms{
        column-gap: 1rem;
        padding: 0px 15px;
    }
    .wrkhmfilms::-webkit-scrollbar{
        display: none;
    }
    .wrkhmtitle{
        margin: 20px;
        font-size: 30px;
    }
    .wrkhmnav{
        margin: 15px 0px;
    }
    .wrkhmnav-link{
        font-size: 15px;
    }
    .film{
        padding: 15px;
        width:250px;
        /* height: 525px; */
    }
    .filmabt{
        font-size: 12px;
        font-weight: 400;
        padding: 5px;
    }
}