*{
  margin: 0;
  padding: 0;
}
:root{
  --whitetxt: #f4f4f4;
  --whitetrans: #f4f4f4e5;
  --greentxt: #214E41;
  --greentrans: #214e41f0;
  --blacktxt: #252525;
  
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  background-color: #f5f5f5;
  font-family: 'Inter', sans-serif;
  font-style: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
