.ftrbase{
    margin-top: 50px;
    display: grid;
    bottom: 0;
    background: #f4f4f4;
}
.ftritem{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    padding: 20px;
}
.ftrnav{
    justify-self: end;
    display: flex;
    list-style: none;
    align-self: end;
}
.ftrnav-link{
    margin: 0 10px;
    font-size: 20px;
    font-weight: 600;
}
.ftrcnt{
    color: var(--blacktxt) !important;
}
.ftritem2{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: var(--greentxt);
    padding: 10px;
    font-weight: 600;
    color: var(--whitetxt);
    /* text-align: center; */
}
.developercnt{
    justify-self: end;
}
.developercnt .fa-instagram,.developercnt .fa-linkedin{
    margin-left: 10px;
    color: var(--whitetxt);
}
@media screen and (max-width: 600px){
    .ftritem img{
        width: 70%;
    }
    .ftrnav-link{
        font-size: 15px;
    }
    .ftritem2{
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }
    .developercnt{
        justify-self: center;
    }
}
@media screen and (max-width: 400px){
    .ftritem{
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }
    .ftrnav{
        justify-self: center;
    }
    .ftritem2 h6{
        font-size: 11px;
    }
    .developercnt{
        font-size: 13px;
    }
}