
.conhmitem{
    display: grid;
    grid-template-columns: 60% 40%;
}
.conhmitem1{
    padding-right: 5%;
    justify-self: center;
    padding: 5%;
}
.conhmitem11{
    display: grid;
    grid-template-columns: 75% 25%;
    justify-items: center;
    align-items: center;
    padding: 2%;
}
.consocial{
    list-style: none;
    font-size: 40px;
}
.fa-phone-square-alt{
    color:rgb(78, 225, 58)
}
.fa-instagram{
    color: red;
}
.fa-twitter{
    color: cornflowerblue;
}
.fa-facebook{
    color:rgb(84, 63, 245)
}
.conhmitem1 img{
    width: 100%;
}
.conhmitem1 h1{
    color: var(--greentxt);
    font-weight: 700;
    font-size: 50px;
}
.conhmitem2{
    background: var(--greentxt);
    padding: 5%;
    color: var(--whitetxt);
}
.contactform{
    padding: 5%;
}
.contactform h3{
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}
.form-label{
    font-weight: 600;
    padding: 5px;
}
.form-control{
    background: var(--whitetxt);
    color: var(--greentxt) !important;
    font-weight: 600;
}

.contactform button{
    border-radius: 30px;
    width: 160px;
    height: 35px;
    margin-top: 15px;
    background: var(--whitetxt);
    border: none;
    color: var(--greentxt);
    font-size: 15px;
    font-weight: 600;
    font-weight: 600;    
}

.contactform button:hover{
    width: 180px;
    height: 40px;
    transition: 0.5s ease-in-out;
    background: var(--whitetxt);
    color: var(--greentxt);
}

@media screen and (max-width: 1100px) {
    .conhmitem1 h1{
        font-size: 40px;
    }
    .contactform h3{
        font-size: 25px
    }
}

@media screen and (max-width: 900px) {
    .conhmitem{
        grid-template-columns: repeat(1, 1fr);
    } 
    .conhmitem1 h1{
        font-size: 40px;
    }
    .consocial{
        font-size: 30px;
    }
    /* .conhmitem11 img{
        align-self: center;

    } */
    .contactform{
        width: 100%;
    }   
}



  