.abthm{
    display: flex;
    background: var(--whitetxt);
    color: var(--blacktxt);
    align-items: center;
    justify-content: center;
    padding: 3%;
}
.abthmitem1
{
    flex: 0 0 50%;
    padding: 20px;
}
.abthmitem1 img{
    width: 100%;
}
.abthmitem2{
    padding: 20px;
}
.abthmitem2 h1{
    font-weight: 700;
}
.abthm2{
    display: flex;
    background: var(--greentxt);
    padding: 3%;
    color: var(--whitetxt);
    align-items: center;
    justify-content: center;
}
.abthmitem3{
    flex: 0 0 50%;
    padding: 20px;
}
.abthmitem3 h1{
    font-size: 40px;  
    font-weight: 700;  
}
.abthmitem3 h5{
    line-height: 150%;
    padding-top: 15px;
}
.abthmitem4{
    padding: 20px;
}
.abthmitem4 img{
    width: 100%;
}

@media screen and (max-width: 800px) {
    .abthm{
        flex-wrap: wrap;
    }
    .abthmitem2 h1{
        font-size: 20px;
    }
    .abthmitem1, .abthmitem3{
        flex: auto;
    }
    .abthm2{
        flex-wrap:wrap-reverse;
    }
    .abthmitem3 h5{
        font-size: 15px;
    }

    
}