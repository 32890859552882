.srvnav{
    display: flex;
    /* background: var(--greentxt); */
    padding: 20px;
    justify-content: center;
    margin-top: 10px;
    border-bottom: 20px var(--greentxt) ;
}
.srvnav button{
    margin: 0 10px;
    width: 130px;
    padding: 5px;
    background: var(--whitetxt);
    border: none;
    border-radius: 20px;
    font-weight: 600;
    color: var(--blacktxt);
}
.srvnav button:focus{
    
    background: var(--greentxt);
    color: var(--whitetxt);
}
.srvhm1{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
    color: var(--blacktxt);
    /* flex-wrap: wrap; */
}
.srvhm1item1{
    flex: 0 0 50%;
    padding: 20px;
}
.srvhm1item1 h1{
    font-size: 50px;
    font-weight: 700;
}

.srvhm1item2 img{
    width:100%;
}
.srvhm1item1 h5{
    padding-top: 20px;
    font-weight: 600;
    line-height: 150%;
}
.srvhm1item1{
    padding: 20px;
}

@media screen and (max-width: 800px) {
    .srvhm1item1 h1{
        font-size: 35px;
    }
    .srvhm1item1 h5{
        font-size: 15px;
    }
}
@media screen and (max-width: 600px) {
    .srvnav{
        padding: 40px;
    }
    .srvnav button{
        font-size: 12px;
        font-weight: 700;
        width: 100px;
        padding:5px 10px;
        margin: 0 5px;
    }
    .srvhm1{
        flex-wrap: wrap-reverse;
    }
    .srvhm1item1{
        flex: auto;
        /* justify-content: center; */
    }
    .srvhm1item2 img{
        width: 100%;
        /* justify-self:center; */
    }
    
}