
.navbritems{
  background: var(--whitetxt);
  height: 65px;
  position: sticky;
  width: 100%;
  /* padding: 32px 144px; */
  display: flex;
  justify-content: center;
  align-items: self-end;
  font-size: 1.2rem;
}

.navbr-logo{
  
  justify-self: start;
  margin-left: 20px;
  justify-content: left;
  width: auto;
  align-items: center;
  height: 60px;
  cursor: pointer;
}

.nav-menu{
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links{
  color: var(--greentxt);
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: 700;
  font-size: 15px;

}

.nav-links:hover {
  background-color: var(--greentxt);
  color: var(--whitetxt);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}


.nav-links-mobile{
  display:none;
}

.btn1{
  display: none;
}



@media screen and (max-width: 970px){
  .navbritems{
    /* position: sticky; */
    position: relative;
    padding: 0px 0px;
  }

  .nav-menu{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85vh !important;
    position: absolute;
    top: 65px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    margin-right: 0 !important;
    justify-content: center;
  }

  .nav-menu.active{
    background: var(--whitetxt);
    left:0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index:  1;
    padding-right: 0 !important;
  }

  .nav-links{
    text-align: center;
    color: var(--greentxt);
    padding: 2rem;
    width: 100%;
    font-size: 20px;
    display: table;
  }
  .nav-links:hover{
    background-color: var(--greentxt);
    color: var(--whitetxt);
    border-radius: 0;
  }

  .navbr-logo{
    position: absolute;
    top: 5px;
    left: 0;
    /* transform:  translate(0% 50%); */
    width: auto;
    align-items: center;
    height: 50px;
  }

  ul{
    padding-inline-start: 0px !important;
  }

  .btn1 {
    display: block;
    position: absolute;
    top: 0;
    right: 60px;
    /* transform: translate(-100%, 60%); */
    /* font-size: 1.8rem; */
    cursor: pointer;
  }
  .btn1 .icon-left {
    transition-duration: 0.5s;
    position: absolute;
    height: 5px;
    width: 15px;
    top: 30px;
    background-color: var(--greentxt);
    left: 0px;
    border-radius: 4px 0px 0px 4px;
  }
  .btn1 .icon-left:before {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 5px;
    background-color: var(--greentxt);
    content: "";
    top: -10px;
    border-radius: 4px 0px 0px 4px;
  }
  .btn1 .icon-left:after {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 5px;
    background-color: var(--greentxt);
    content: "";
    top: 10px;
    border-radius: 4px 0px 0px 4px;
  }
  .btn1 .icon-left:hover {
    cursor: pointer;
  }
  .btn1 .icon-right {
    transition-duration: 0.5s;
    position: absolute;
    height: 5px;
    width: 15px;
    top: 30px;
    background-color: var(--greentxt);
    left: 15px;
    border-radius: 0px 4px 4px 0px;
  }
  .btn1 .icon-right:before {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 5px;
    background-color: var(--greentxt);
    content: "";
    top: -10px;
    border-radius: 0px 4px 4px 0px;
  }
  .btn1 .icon-right:after {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 5px;
    background-color: var(--greentxt);
    content: "";
    top: 10px;
    border-radius: 0px 4px 4px 0px;
  }
  .btn1.open .icon-left {
    transition-duration: 0.5s;
    background: transparent;
  }
  .btn1.open .icon-left:before {
    transform: rotateZ(45deg) scaleX(1.4) translate(2px, 2px);
  }
  .btn1.open .icon-left:after {
    transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -2px);
  }
  .btn1.open .icon-right {
    transition-duration: 0.5s;
    background: transparent;
  }
  .btn1.open .icon-right:before {
    transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 2px);
  }
  .btn1.open .icon-right:after {
    transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -2px);
  }
  .btn1:hover {
    cursor: pointer;
  }
  
}





  